var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "workerTable",
          attrs: {
            title: "작업자 목록",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.workPermit.workers,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && _vm.isWriting,
            selection: "multiple",
            rowKey: "sopWorkerId",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  props.row.keyinFlag !== "Y"
                    ? [_vm._v(" " + _vm._s(props.row[col.name]) + " ")]
                    : [
                        col.name !== "birthDate"
                          ? [
                              _c("c-text-column", {
                                attrs: {
                                  editable: _vm.editable,
                                  disabled: !_vm.isWriting,
                                  col: col,
                                  props: props,
                                },
                                on: {
                                  datachange: function ($event) {
                                    return _vm.datachange(props, col)
                                  },
                                },
                              }),
                            ]
                          : [
                              _c("c-datepicker", {
                                staticClass: "tableDatepicker",
                                attrs: {
                                  dense: "",
                                  type: "date",
                                  disabled: !_vm.isWriting,
                                },
                                on: {
                                  datachange: (val) =>
                                    _vm.datachange(props, col),
                                },
                                model: {
                                  value: props.row[col.name],
                                  callback: function ($$v) {
                                    _vm.$set(props.row, col.name, $$v)
                                  },
                                  expression: "props.row[col.name]",
                                },
                              }),
                            ],
                      ],
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: { label: "직접추가", icon: "add" },
                        on: { btnClicked: _vm.addVendorUserKeyIn },
                      })
                    : _vm._e(),
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: { label: "추가(업체)", icon: "add" },
                        on: { btnClicked: _vm.addVendorUser },
                      })
                    : _vm._e(),
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: { label: "추가(현업)", icon: "add" },
                        on: { btnClicked: _vm.addUser },
                      })
                    : _vm._e(),
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: {
                          label: "제외",
                          icon: "remove",
                          showLoading: false,
                        },
                        on: { btnClicked: _vm.remove },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }